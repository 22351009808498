import React, { useEffect , useState} from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import generativeAi from './generativeAiArray.jsx';
import aiMl from './aiMl.jsx'
import Footer from '../Footer/Footer.jsx';

export default function Services() {
  const [selectedCategory, setSelectedCategory] = useState('Generative AI');
  useEffect(() => {
    document.title = 'Service | Indenta Ai';
    AOS.init({
      duration: 1000,
      once: false,
    });
    AOS.refresh();
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem', margin: 'auto' , background : 'linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%)' , padding : '7rem'}}>
        <Button sx={{background: 'linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%)'}} onClick={() => setSelectedCategory('Generative AI')} variant="contained">Generative AI</Button>
        <Button sx={{background: 'linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%)'}} onClick={() => setSelectedCategory('AI & ML')}variant="contained">AI & ML</Button>
      </div>

      <div>
        {(selectedCategory === 'Generative AI' ? generativeAi : aiMl).map((item, index) => (
          <Container
            key={index}
            maxWidth="lg"
            sx={{
              mt: { xs: 4, sm: 8 }, px: { xs: 3, sm: 1 },
            // backgroundColor: 'linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%)',
            // backgroundColor: index % 2 === 0 ? '#0D0D0D' : 'linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%)',
            }}
          >
            <Box
              sx={{
                gap: '4rem',
                padding : '2rem',
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  md: index % 2 === 0 ? 'row' : 'row-reverse',
                },
                background: index % 2 === 0 ? '#0D0D0D' : 'linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%)',
                // backgroundColor: index % 2 === 0 ? '#0D0D0D' : 'linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%)',
                // background: 'linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%)',
                // background: 'linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%)',
                alignItems: 'center',
                justifyContent: 'space-around',
                mt: { xs: 4, sm: 8 },
              }}
            >
              <Box
                sx={{
                  textAlign: { xs: 'center', md: 'left' },
                  mb: { xs: 4, md: 0 },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{ fontWeight: 'bold', mt: 2, color: '#08F89F' }}
                  data-aos="fade-up"
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: '#9C9C9C', mt: 2 }}
                  data-aos="fade-up"
                >
                  {item.para}
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: { xs: 4, md: 0 },
                  maxWidth: '100%',
                  width: { xs: '90%', sm: '70%', md: '50%' },
                }}
                data-aos="fade-up"
              >
                <img
                  src={item.img}
                  alt="Robot"
                  style={{ width: '100%', maxWidth: '1000px' }}
                />
              </Box>
            </Box>
          </Container>
        ))}
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}
