import React, { Fragment } from 'react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
// import indentaLogo from './files/white-indenta-ai-logo.svg'
import indentaLogo from './files/logo1.png'

// const navigation = [
//     // { name: 'Home', path: '/', current: false },
//     { name: 'Service', path: '/service', current: false },
//     { name: 'About', path: '/about', current: false },
//     { name: 'Our Team', path: '/team', current: false },
//     // { name: 'Project', path: '/project', current: false },
//     { name: 'Contact', path: '/contact', current: false },
// ];
const navigation = [
    // { name: 'Home', path: '/', current: false },
    { name: 'Service', path: '/service', current: false },
    { name: 'About',  current: false },
    { name: 'Our Team', current: false },
    // { name: 'Project', path: '/project', current: false },
    { name: 'Contact',  current: false },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
    const navigate = useNavigate();

    return (
        <Disclosure as="nav" style={{borderBottom: '1px solid gray',}}>
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-evenly">
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        style={{ cursor: 'pointer', marginTop: 'auto' }}
                                        onClick={() => navigate('/')}
                                        className="h-8 w-auto"
                                        src={indentaLogo}
                                        alt="Your Company"
                                    />
                                </div>
                                <div className="hidden sm:ml-6 sm:block" style={{ margin: 'auto' }}>
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <button
                                                key={item.name}
                                                style={{ cursor: 'pointer' , fontSize : '1rem'}}
                                                onClick={() => navigate(item.path)}
                                                className={classNames(
                                                    item.current ? 'text-white' : 'text-white hover:text-gray-300',
                                                    'rounded-md px-3 py-2 text-sm font-medium'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </button>
                                        ))}
                                        {/* Search input in main menu for small screens */}

                                    </div>
                                </div>
                                <div>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <SearchSharpIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                        </div>
                                        <input
                                            type="text"
                                            className="hidden sm:block w-full sm:w-auto rounded-md bg-transparent text-white placeholder-white focus:outline-none pl-10 pr-4 py-2"
                                            placeholder="Search..."
                                        />
                                        {/* <input
                                            type="text"
                                            className="hidden sm:block w-full sm:w-auto rounded-md bg-transparent text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 pl-10 pr-4 py-2"
                                            placeholder="Search..."
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    style={{ cursor: 'pointer' , fontSize : '1rem'}}
                                    onClick={() => navigate(item.path)}
                                    className={classNames(
                                        item.current ? 'bg-gray-900 text-white' : 'text-white hover:text-gray',
                                        'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                            {/* Search input in mobile menu for small screens */}
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <SearchSharpIcon className="hidden h-5 w-5 text-white" aria-hidden="true" />
                                </div>
                                <input
                                    type="text"
                                    className="block w-full rounded-md bg-transparent text-white placeholder-white focus:outline-none pl-10 pr-4 py-2"
                                    placeholder="Search..."
                                />
                                    {/* type="text"
                                    className="block w-full rounded-md bg-transparent text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 pl-10 pr-4 py-2"
                                    placeholder="Search..."
                                /> */}
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}
