import roboticAutomation from './files/roboticAutomation.png';
import one from './files/11.webp'
import two from './files/12.webp'
import three from './files/13.png'
import four from './files/14.webp'
import five from './files/15.webp'
import six from './files/16.webp'
import seven from './files/17.png'
import eight from './files/18.png'
import nine from './files/19.png'
import ten from './files/20.webp'
import eleven from './files/21.webp'
import twelve from './files/22.png'

const aiMl = [
    {
      img : one,
      title : 'Ai Developement Service',
      para : 'Unlock the data value and solve complex problems with Artificial Intelligence. Revolutionize your operational workflows and conquer intricate challenges with our AI development services. We skillful craft of AI algorithms to liberate you from monotonous tasks, instilling a swifter decision-making process.',
    },
    {
      img : one,
      title : 'Enterprise Ai Development',
      para : 'We develop robust enterprise AI solutions harnessing Machine Learning for smarter decision-making and leveraging Predictive Analytics for future-proof strategies. Get a competitive edge with Natural Language Processing (NLP) for enhanced customer interactions, Large Language Models (LLMs) for advanced problem-solving, and gain sharper insights with our Advanced Data Analysis.',
    },
    {
      img : two,
      title : 'Ai Consulting Services',
      para : 'Our AI Consulting services aim to build an intelligent and autonomous system that can solve business challenges. We blend powerful AI and cutting-edge solutions with deep strategic thinking and domain expertise to help clients innovate and develop new opportunities.',
    },
    {
      img : three,
      title : 'Ai Chatbot Development',
      para : 'We focus on crafting smart and conversational chatbots that change the game in customer engagement and take business growth to new heights with our AI chatbot development services.',
    },
    {
      img : four,
      title : 'Enterprise Ai Chatbot Development',
      para : 'We focus on crafting smart and conversational AI chatbots that change the game in customer engagement and take business growth to new heights with our Enterprise AI chatbot development services. Our services suite is designed to reshape customer interaction, smooth out operational kinks, and inject innovation into everything we do.',
    },
    {
      img : five,
      title : 'Computer Vision Development Services',
      para : 'Our team of computer vision experts crafts tailor-made image and video analysis applications for both machine vision and computer vision systems. We construct software with multifaceted capabilities, spanning from face scrutiny, instant gesture and movement detection, to machine vision and image categorization.',
    },
    {
      img : six,
      title : 'Ai Engineers',
      para : 'Looking to advance the technological capabilities of the company? It is taken care of by our team of AI engineers. They are familiar with PyTorch, TensorFlow, and Caffe inside and out. They are professionals in creating solutions using Machine Learning, Neural Networks, and even the complex subjects of Deep Learning and Computer Vision, so it is not just about the tools. They design solutions that truly understand what the end user wants, whether it is by delving deeply into data mining or producing images that convey a narrative.',
    },
    {
      img : seven,
      title : 'ML Development',
      para : 'We empower businesses to achieve remarkable results, create significant breakthroughs, and enhance operational effectiveness through our cutting-edge Machine Learning Development solutions.',
    },
    {
      img : seven,
      title : 'Embeddings As A Service',
      para : 'The expertise we hold in handling different data categories and crafting vector embeddings for a multitude of functions places us at the forefront of delivering optimal and trustworthy embeddings as a service for AI-driven solutions.',
    },
    {
      img : eight,
      title : 'ML Model Engineering',
      para : 'Boasting profound knowledge in machine learning frameworks, libraries, NLP, deep learning, data visualization/processing tools, and DevOps, we craft potent machine learning models that can ignite revolutionary business results. Be it constructing a tailor-made ML model from the ground up, fine-tuning an existing one, or launching it in the cloud, we’ve got it all figured out.',
    },
    {
      img : nine,
      title : 'MLOps Consulting Services',
      para : 'Embrace MLOps to revolutionize your AI deployment, ensuring seamless, scalable, and efficient machine learning models that drive unparalleled business growth and innovation.',
    },
    {
      img : ten,
      title : 'AIaaS',
      para : 'With our specialized AI-as-a-Service (AIaaS) solutions, experience the cutting-edge AI. We offer advanced machine learning, unique recommendation systems, image/video analysis, predictive analytics, and other services all geared towards enhancing your business.',
    },
  ]

  export default aiMl;