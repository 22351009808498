import roboticAutomation from './files/roboticAutomation.png'
import one from './files/1.webp';
import two from './files/2.png';
import three from './files/3.webp';
import four from './files/4.webp';
import five from './files/5.webp';
import six from './files/6.webp';
import seven from './files/7.webp';
import eight from './files/8.webp';
import nine from './files/9.webp';
import tenth from './files/10.webp';

const generativeAi = [
  {
    title: 'Generative Ai Development',
    para: 'We help you to utilize the potential of Generative AI to build intelligent systems capable of learning, adapting, and evolving. Our team of specialists focuses on providing state-of-the-art generative AI development services that are customized to suit your individual business requirements.',
    img: one,
  },
  {
    title: 'Generative Ai Consulting',
    para: 'Venture into the AI frontier with our specialized generative AI consulting services. Mastering GANs, VAEs, autoencoders, RLHF, and myriad model types, we enlighten businesses on strategic AI deployment. Our crafted solutions weave seamlessly into workflows, driving enhanced productivity and efficiency.',
    img: two,
  },
  {
    title: 'Enterprise Generative Ai Development',
    para: 'We help Enterprises to utilize the potential of Generative AI to build intelligent systems capable of learning, adapting, and evolving. Our team of specialists focuses on providing state-of-the-art Enterprise generative AI development services that are customized to suit your individual business requirements.',
    img: three,
  },
  {
    title: 'Chatgpt Integration Services',
    para: 'Our ChatGPT developers can help you with ChatGPT model integration into your existing software or application. We are experts in NLP and very well understand the capabilities of ChatGPT. We provide ChatGPT integration service to help you with customization of your training data, adjustments and fine-tuning the ChatGPT model.',
    img: four,
  },
  {
    title: 'LLM Development',
    para: 'We have AI engineers who are experts in natural language processing (NLP) and machine learning (ML). They are proficient in large language model (LLM) development, which helps meet clients’ specific needs and fine-tune the training model to generate high-quality content and data.',
    img: five,
  },
  {
    title: 'Generative Ai Integration Service',
    para: 'In crafting sophisticated solutions, we harness the power of generative AI integration, designed and adapted meticulously to align with distinctive business specifications. These solutions then seamlessly blend into existing operational patterns. This approach lays a clear path towards streamlined processes, fuels the ignition of breakthrough concepts, and optimizes operational efficiency, all while drawing from the boundless potential that generative AI holds.',
    img: six,
  },
  {
    title: 'Adaptive Ai Development',
    para: 'Leverage the power of Adaptive Artificial Intelligence to surpass your competitors’ offerings and enhance your product’s overall performance. Our proficient team of AI engineers possesses expertise in machine learning, deep learning, neural networks, natural language processing (NLP), and other subsets of AI to deliver exceptional, tailor-made solutions with the help of Adaptive AI development.',
    img: seven,
  },
  {
    title: 'Chatgpt Developers',
    para: 'Indenta Ai is an expert in OpenAI models and AI powered solutions. We can help you build ChatGPT custom chatbots, image bots and language applications. Our ChatGPT developers are well-versed in Machine Learning (ML), Natural Language Processing (NLP) and Deep Learning.',
    img: eight,
  },
  {
    title: 'Hire Prompt Engineers',
    para: 'As a digital product company specializing in the development of AI products and services, we offer prompt engineering solutions that can assist you in constructing AI models using cutting-edge technologies such as Midjourney, DALL-E, Stable Diffusion, and OpenAI. Our team of dedicated prompt engineers boasts extensive experience in fine-tuning and customizing prompts, enabling you to efficiently deploy generative models.',
    img: nine,
  },
  {
    title: 'Transformer Model Development',
    para: 'Immerse in the innovative horizon of data processing and analytical exploration with Markovate’s bespoke Transformer model development services. Navigating the complex landscape of AI with bespoke, optimized, and seamlessly integrated Transformer models for advanced data processing.',
    img: tenth,
  },
]

export default generativeAi;
