import React from 'react'
import getStartedBtn from './Files/getStartedBtn.svg'
import './GetStartedButton.css'

export default function GetStartedButton() {
  return (
    <div>
        <button>
          <img src={getStartedBtn} alt="" className='GetStartedButton' />
        </button>
    </div>
  )
}
