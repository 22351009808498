import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Box, Typography, Container, Card, CardContent, Grid } from '@mui/material';
// import robot1 from './files/robot1.webp';
import robot1 from './files/robotOne.svg';
import GetStartedButton from '../../screens/Button/GetStartedButton';
import Footer from '../Footer/Footer';
import whoWeAreImage from './files/whoWeAreImage.png'
import roboticAutomation from './files/roboticAutomation.png'
import cgdc from './files/companies/cgdconsulting_logo.jpeg'
import bebrokr from './files/companies/bebrokr.png'
import newEra from './files/companies/newEra.webp'
import mafco from './files/companies/mafco.webp'

export default function HomePage() {
  const serviceCard = [
    {
      img: roboticAutomation,
      title: 'Genrative Ai',
      para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, tempora dignissimos, doloribus numquam consectetur",
    },
    {
      img: roboticAutomation,
      title: 'AI & ML',
      para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, tempora dignissimos, doloribus numquam consectetur",
    },
  ]
  // const serviceCard = [
  //   {
  //     img: roboticAutomation,
  //     title: 'Genrative Ai',
  //     para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, tempora dignissimos, doloribus numquam consectetur",
  //   },
  //   {
  //     img: roboticAutomation,
  //     title: 'Fintect',
  //     para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, tempora dignissimos, doloribus numquam consectetur",
  //   },
  //   {
  //     img: roboticAutomation,
  //     title: 'Cloud',
  //     para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, tempora dignissimos, doloribus numquam consectetur",
  //   },
  //   {
  //     img: roboticAutomation,
  //     title: 'Software Development',
  //     para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, tempora dignissimos, doloribus numquam consectetur",
  //   },
  // ]
  const clientImages = [
    { src: cgdc, alt: 'CGDC' },
    { src: bebrokr, alt: 'BeBrokr', backgroundColor: '#f0f0f0' },
    { src: newEra, alt: 'New Era' },
    { src: mafco, alt: 'Mafco' },
  ];

  useEffect(() => {
    document.title = 'Home | Indenta Ai';
    AOS.init({
      duration: 1000,
      once: false,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: { xs: 4, sm: 8 }, px: { xs: 3, sm: 1 } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'space-around',
            mt: { xs: 4, sm: 8 },
          }}
        >
          <Box sx={{ textAlign: { xs: 'center', md: 'left' }, mb: { xs: 4, md: 0 } }}>
            <Typography variant="h6" sx={{ textTransform: 'uppercase', color: '#08EB92' }} data-aos="fade-up">
              | Next Generation platform
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', mt: 2, color: 'text.primary' }} data-aos="fade-up">
              <span style={{ color: '#08F89F' }}>Fueling Efficiency,</span>{' '}
              <span style={{ color: '#08F89F' }}>and Productivity through AI</span>
            </Typography>
            <Typography variant="body1" sx={{ color: '#9C9C9C', mt: 2 }} data-aos="fade-up">
              Indenta Ai is empowering businesses with innovative solutions, guiding{' '}
              <br className="hidden md:block" /> them towards a successful journey of digital{' '}
              <br className="hidden md:block" /> transformation.
            </Typography>
            <Box sx={{ mt: 4 }} data-aos="fade-up">
              <GetStartedButton />
            </Box>
          </Box>
          <Box sx={{ mt: { xs: 4, md: 0 }, maxWidth: '100%', width: { xs: '90%', sm: '70%', md: '50%' } }} data-aos="fade-up">
            <img src={robot1} alt="Robot" style={{ width: '100%', maxWidth: '1000px' }} />
          </Box>
        </Box>
      </Container>

      <Container
        maxWidth="lg"
        sx={{
          mt: { xs: 4, sm: 4 },
          px: { xs: 3, sm: 1 },
          mb: '2rem',
          background: 'linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%)',
          py: '2rem',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
        }}
        data-aos="fade-up"
      >
        <Box sx={{ textAlign: { xs: 'center', md: 'left' }, mb: { xs: 4, md: 0 }, flex: 1 }}>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'white' }} data-aos="fade-up">
            Tapping Intelligence <br className="hidden md:block" /> Through Talent
          </Typography>
        </Box>
        <Box sx={{ textAlign: { xs: 'center', md: 'left' }, mb: { xs: 4, md: 0 }, flex: 1 }}>
          <Typography variant="body1" sx={{ color: 'white', mt: 2 }} style={{ opacity: '0.6' }} data-aos="fade-up">
            Indenta Ai provides innovative software solutions designed to enhance efficiency and streamline processes. Our advanced tools seamlessly integrate into existing systems, offering a user-friendly experience. Leveraging cutting-edge technology, Indenta Ai ensures that businesses stay ahead in a competitive landscape, fostering growth and success.
          </Typography>
        </Box>
      </Container>

      <Container maxWidth="lg" sx={{ mt: { xs: 4, sm: 8 }, px: { xs: 3, sm: 1 } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row-reverse' },
            alignItems: 'center',
            justifyContent: 'space-beween',
            mt: { xs: 4, sm: 8 },
            gap: '13rem'
          }}
        >

          <Box sx={{ textAlign: { xs: 'center', md: 'left' }, mb: { xs: 4, md: 0 }, }}>
            <Typography variant="h6" sx={{ textTransform: 'uppercase', color: '#08EB92' }} data-aos="fade-up">
              | Next Generation platform
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', mt: 2, color: 'text.primary' }} data-aos="fade-up">
              <span style={{ color: '#08F89F' }}>Seamless solutions</span>{' '}
              <span style={{ color: '#08F89F' }}> for every tech horizon</span>
            </Typography>
            <Typography variant="body1" sx={{ color: '#9C9C9C', mt: 2, maxWidth: '30rem' }} data-aos="fade-up" >
              {/* Indenta Ai is at the forefront of providing seamless solutions tailored to meet the demands of every technological horizon. Our innovative approaches empower businesses, guiding them through the ever-changing landscape of digital transformation towards a successful journey. */}
              Indenta Ai is at the forefront of providing seamless solutions tailored to meet the demands of {' '} every technological horizon. Our innovative approaches empower businesses, guiding them through the  ever-changing landscape of digital transformation towards a successful journey.
            </Typography>
            <Box sx={{ mt: 4 }} data-aos="fade-up">
              <GetStartedButton />
            </Box>
          </Box>
          <Box sx={{ mt: { xs: 4, md: 0 }, maxWidth: '100%', width: { xs: '90%', sm: '70%', md: '50%' } }} data-aos="fade-up">
            <img src={robot1} alt="Robot" style={{ width: '100%', maxWidth: '1000px' }} />
          </Box>
        </Box>
      </Container>

      <Container
        maxWidth="lg"
        sx={{
          mt: { xs: 4, sm: 8 },
          px: { xs: 3, sm: 1 },
          mb: '2rem',
          background: 'linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%)',
          py: '2rem',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        data-aos="fade-up"
      >
        <Box sx={{ textAlign: { xs: 'center', md: 'left' }, mb: { xs: 4, md: 0 }, flex: 1 }}>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'white' }} data-aos="fade-up" style={{ float: 'right' }}>
            Building innovative solutions across industries
          </Typography>
        </Box>
        <Box sx={{ textAlign: { xs: 'center', md: 'left' }, mb: { xs: 4, md: 0 }, flex: 1 }}>
          <Typography variant="body1" sx={{ color: 'white', mt: 2 }} style={{ opacity: '0.6', float: 'right' }} data-aos="fade-up">
            <GetStartedButton />
          </Typography>
        </Box>
      </Container>

      <Container maxWidth="lg" sx={{ mt: { xs: 4, sm: 8 }, px: { xs: 3, sm: 1 } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'space-around',
            mt: { xs: 4, sm: 8 },
          }}
          data-aos="fade-up"
        >
          <Box sx={{ textAlign: { xs: 'center', md: 'left' }, mb: { xs: 4, md: 0 } }}>
            <Typography variant="h6" sx={{ textTransform: 'uppercase', color: '#08EB92' }} data-aos="fade-up">
              | Next Generation platform
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', mt: 2, color: 'text.primary' }} data-aos="fade-up">
              <span style={{ color: '#08F89F' }}>Elevating Efficiency</span>{' '}
              <span style={{ color: '#08F89F' }}>with Cutting-Edge Technology</span>
            </Typography>
            <Typography variant="body1" sx={{ color: '#9C9C9C', mt: 2 }} data-aos="fade-up">
              At Indenta Ai, we're dedicated to elevating efficiency through the integration of cutting-edge {' '}
              <br className="hidden md:block" /> technology. Our solutions are designed to streamline processes, optimize resources, and enhance{' '}
              <br className="hidden md:block" /> transformative results that propel businesses towards unparalleled success.
            </Typography>
            <Box sx={{ mt: 4 }} data-aos="fade-up">
              <GetStartedButton />
            </Box>
          </Box>
          <Box sx={{ mt: { xs: 4, md: 0 }, maxWidth: '100%', width: { xs: '90%', sm: '70%', md: '50%' } }} data-aos="fade-up">
            <img src={robot1} alt="Robot" style={{ width: '100%', maxWidth: '1000px' }} />
          </Box>
        </Box>
      </Container>

      <Container maxWidth="lg" sx={{ mt: { xs: 2, sm: 4 }, marginBottom: '5rem', padding: '1rem' }}>
        <Box
          sx={{
            background: 'linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%)',
            mt: { xs: 4, sm: 8 },
            padding: '1rem'
          }}
        >
          <Typography variant="h5" sx={{ textTransform: 'uppercase', color: '#08EB92', textAlign: 'center' }} data-aos="fade-up">
            | Services
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mt: 2, color: 'text.primary', textAlign: 'center' }} data-aos="fade-up">
            <span style={{ color: '#08F89F' }}>Our Purpose Is To Deliver Excellence In Service And Execution</span>
          </Typography>
          <Grid container spacing={2} justifyContent="center" mt={3}>
            {serviceCard.map((item, index) => (
              <Grid item key={index} xs={12} md={3}>
                <Card sx={{ border: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center', background: 'transparent' }} data-aos="fade-up">
                  <CardContent>
                    <img src={item.img} alt="Card" style={{ width: '100%', height: 'auto', maxWidth: '200px', display: 'flex', justifyContent: 'center', margin: 'auto' }} />
                    <Typography variant="h5" component="h2" mt={2} sx={{ color: 'white', textAlign: 'center', fontSize: '1.3rem', fontWeight: '600' }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" mt={2} sx={{ color: 'white', textAlign: 'center', maxWidth: '208px', width: '100%' }}>
                      {item.para}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box
          sx={{
            color: '#fff',
            padding: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '2rem',
            flexDirection: { xs: 'column', md: 'row' },
            marginBottom: '4rem'
          }}
        >
          <Grid item xs={12} md={4} data-aos="fade-up">
            <img
              data-aos="fade-up"
              src={whoWeAreImage}
              alt="AI Concept 1"
              style={{ width: '100%', height: 'auto' }}
            />
          </Grid>
          <Grid sx={{ display: 'flex', flexDirection: 'column' }} >
            <Box
              sx={{
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              <Typography variant="h4" component="h1" data-aos="fade-up">
                We specialise in Serving businesses by hard implementing AI
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginTop: '20px'
              }}
              data-aos="fade-up"
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography
                  variant="h2"
                  sx={{ color: '#00FFCE' }}
                  data-aos="fade-up"
                >
                  <GetStartedButton />
                </Typography>
                {/* <Typography data-aos="fade-up">
                  Years of experience
                </Typography> */}
              </Box>
              {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography
                  variant="h2"
                  sx={{ color: '#00FFCE' }}
                  data-aos="fade-up"
                >
                  10
                </Typography>
                <Typography data-aos="fade-up">
                  Most awarded for AI Product
                </Typography>
              </Box> */}
            </Box>
          </Grid>
        </Box>
      </Container>

      {/* <Container maxWidth="lg" sx={{ mt: { xs: 2, sm: 4 }, marginBottom: '5rem', padding: '1rem' }}>
        <Box
          sx={{
            background: 'linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%)',
            mt: { xs: 4, sm: 8 },
            padding: '1rem',
          }}
        >
          <Typography
            variant="h5"
            sx={{ textTransform: 'uppercase', color: '#08EB92', textAlign: 'left' }}
            data-aos="fade-up"
          >
            | Sucessfull
          </Typography>
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', mt: 2, color: 'text.primary', textAlign: 'left' }}
            data-aos="fade-up"
          >
            <span style={{ color: '#08F89F' }}>Our Clients</span>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '2rem',
              mt: 4,
            }}
            data-aos="fade-up"
          >
            {clientImages.map((image, index) => (
              <Box key={index} sx={{
                maxWidth: '160px', maxHeight: '100px', flex: '1 1 auto', cursor : 'pointer'
              }}>
                <img src={image.src} alt={image.alt} style={{ maxWidth: '100%', maxHeight: '100%' }}  data-aos="fade-up" />
              </Box>
            ))}
          </Box>
        </Box>
      </Container> */}

      <Footer />
    </>
  );
}