// import React from 'react'
// import indentaLogo from './files/white-indenta-ai-logo.svg'
// import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
// import PhonePausedOutlinedIcon from '@mui/icons-material/PhonePausedOutlined';

// export default function Footer() {
//   return (
//     <div style={{ position: 'fixed', bottom: '0', marginBottom : '2rem' , border : '1px solid gray'}}>
//       <section style={{display : 'flex' , listStyle : 'none' , justifyContent : 'space-between' , gap : '3rem' , paddingLeft : '6rem' , paddingRight : '5rem' , paddingTop : '1rem' , paddingBottom : '1rem'}}>
//         <div>
//           <img src={indentaLogo} alt="" className='h-8 w-auto' />
//         </div>
//         <div style={{display : 'flex' , flexDirection : 'column', gap : '1rem'}}>
//           <section style={{display : 'flex' , gap : '1rem'}}>
//             <li><MarkunreadOutlinedIcon /></li>
//             <li>support@indenta.ai</li>
//           </section>
//           <section style={{display : 'flex' , gap : '1rem'}}>
//             <li><PhonePausedOutlinedIcon /></li>
//             <li>+12 345 6789</li>
//           </section>
//         </div>
//         <div style={{display : 'flex' , alignContent : 'baseline' , position : 'relative' , bottom : '-37px'}}>
//           <li>&copy; 2024 Your Company Name. All rights reserved.</li>
//         </div>
//         <div style={{display : 'flex', flexDirection : 'column' , gap : '0.7rem'}}>
//           <section style={{display : 'flex' , justifyContent : 'space-evenly' , gap : '2rem' , borderBottom : '5px solid #1D1D1D' , paddingBottom : '0.3rem'}}>
//             <li>Company</li>
//             <li>Service</li>
//             <li>Contact Us</li>
//             <li>Privacy Policy</li>
//             <li>Terms of Use</li>
//           </section>
//           <section style={{display : 'flex' , justifyContent : 'flex-end' , gap : '0.6rem'}}>
//             <li>Facebook</li>
//             <li>Instgram</li>
//             <li>Twitter</li>
//             <li>Youtube</li>
//             <li>Terms of Use</li>
//           </section>
//         </div>
//       </section>
//     </div>
//   )
// }
import React from 'react'
// import indentaLogo from './files/white-indenta-ai-logo.svg'
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import PhonePausedOutlinedIcon from '@mui/icons-material/PhonePausedOutlined';
import { Box, Grid, Typography } from '@mui/material';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import indentaLogo from './files/logo1.png'

export default function Footer() {
  return (
    <Box sx={{  width: '100%', borderTop: '1px solid gray',  p: 2}}>
      <Grid container spacing={2} alignItems="center" justifyContent="space-evenly">
        <Grid item xs={12} sm={2} textAlign={{ xs: 'left', sm: 'left' }}>
          <img src={indentaLogo} alt="Indenta Logo" style={{ height: '32px', width: 'auto' }} />
        </Grid>
        <Grid item xs={12} sm={2} textAlign={{ xs: 'left', sm: 'left' }}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" alignItems="center" gap={1}>
              <MarkunreadOutlinedIcon />
              <Typography variant="body2">support@ai.indenta-technologies.com</Typography>
            </Box>
            {/* <Box display="flex" alignItems="center" gap={1}>
              <PhonePausedOutlinedIcon />
              <Typography variant="body2">+971 50 248 1131</Typography>
            </Box> */}
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} textAlign="left">
          <Typography variant="body2">
            &copy; 2024 Your Company Name. All rights reserved.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" justifyContent="space-around" borderBottom="5px solid #1D1D1D" pb={0.3} gap={1}>
              <Typography variant="body2">Company</Typography>
              <Typography variant="body2">Service</Typography>
              <Typography variant="body2">Contact</Typography>
              <Typography variant="body2">Privacy</Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" gap={1} sx={{cursor : 'pointer'}}>
              <Typography variant="body2" className='socialIcons' sx={{marginLeft: '0.6rem'}}><FacebookOutlinedIcon sx={{transform: 'rotate(-45deg)' , color : '#0ACB83'}} onClick={() => window.open("https://www.facebook.com/", "_blank")} /> </Typography>
              <Typography variant="body2" className='socialIcons' sx={{marginLeft: '0.6rem'}}><InstagramIcon sx={{transform: 'rotate(-45deg)' , color : '#0ACB83'}} /></Typography>
              <Typography variant="body2" className='socialIcons' sx={{marginLeft: '0.6rem'}}><XIcon sx={{transform: 'rotate(-45deg)' , color : '#0ACB83'}} /></Typography>
              <Typography variant="body2" className='socialIcons' sx={{marginLeft: '0.6rem'}}><YouTubeIcon sx={{transform: 'rotate(-45deg)' , color : '#0ACB83'}} /></Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
