import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom"
import React from "react";

import Home from "../pages/HomePage/HomePage";
import Services from "../pages/Services/Services";
import About from "../pages/About/About";
import Team from "../pages/Team/Team";
import Project from "../pages/Project/Project";
import Contact from "../pages/Contact/Contact";

import Navbar from "../pages/navbar/Navbar";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/home',
                element: <Home />,
            },
            {
                path: '/homes',
                element: <Home />,
            },
            {
                path: '/service',
                element: <Services />,
            },
            {
                path: '/services',
                element: <Services />,
            },
            // {
            //     path: '/About',
            //     element: <About />,
            // },
            // {
            //     path: '/team',
            //     element: <Team />,
            // },
            // {
            //     path: '/ourteam',
            //     element: <Team />,
            // },
            // {
            //     path: '/teams',
            //     element: <Team />,
            // },
            // {
            //     path: '/ourteams',
            //     element: <Team />,
            // },
            // {
            //     path: '/project',
            //     element: <Project />,
            // },
            // {
            //     path: '/projects',
            //     element: <Project />,
            // },
            // {
            //     path: '/contact',
            //     element: <Contact />,
            // },
            // {
            //     path: '/contacts',
            //     element: <Contact />,
            // },
            // {
            //     path: '/ourcontact',
            //     element: <Contact />,
            // },
            // {
            //     path: '/ourcontacts',
            //     element: <Contact />,
            // },
            // {
            //     path: '/contactus',
            //     element: <Contact />,
            // },
            {
                path: '/',
                element: <Home />,
            },
        ]
    }
]);

function Router() {
    return <RouterProvider router={router} />
}

function Main() {
    return (
        <React.Fragment>
            <Navbar />
            <Outlet />
        </React.Fragment>

    )
}
export default Router